import { CanActivateFn } from '@angular/router';

export const discordGuard: CanActivateFn = (route, state) => {
  if (state.url === '/discord') {
    document.defaultView?.open('https://discord.gg/xq9JpJ3');

    return false;
  }

  return true;
};
