<div class='layout-footer surface-card'>
<!--  <span class='flex flex-column md:flex-row font-medium align-items-center'>-->
<!--    <div class='flex flex-row align-items-center'>-->
<!--      <span>{{ "LAYOUT.FOOTER.BEDROCK_TWEAKS" | translate }} </span>-->
<!--      <span class='ml-1'>{{ year }}</span>-->
<!--      <span class='mx-2'>{{ "|" }}</span>-->
<!--      <span>{{ version }}</span>-->
<!--    </div>-->
<!--    <span class='mx-2 hidden md:block'>{{ "|" }}</span>-->
<!--    <a [routerLink]="'/privacy'"><span>{{ "LAYOUT.FOOTER.PRIVACY" | translate }}</span></a>-->
<!--    <span class='mx-2 hidden md:block'>{{ "|" }}</span>-->
<!--    <a [routerLink]="'/terms'"><span>{{ "LAYOUT.FOOTER.TERMS" | translate }}</span></a>-->
<!--    <span class='mx-2 hidden md:block'>{{ "|" }}</span>-->
<!--    <a [routerLink]="'/discord'"><span>{{ "LAYOUT.FOOTER.DISCORD" | translate }}</span></a>-->
<!--  </span>-->
</div>
