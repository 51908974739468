// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { PrimeNGConfig } from 'primeng/api';
import { defaultLang, languages } from '@/modules/i18n/translation.module';

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  constructor(
    private _translate: TranslateService,
    private _storage: LocalStorageService,
    private _primeNgConfig: PrimeNGConfig,
  ) {
    this._translate.addLangs(languages);
    this._translate.setDefaultLang(defaultLang);

    this._translate.get('PRIMENG').subscribe(res => this._primeNgConfig.setTranslation(res));
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): string {
    return this._storage.retrieve(LOCALIZATION_LOCAL_STORAGE_KEY) || this._translate.getDefaultLang();
  }

  setLanguage(lang: string): void {
    if (lang) {
      this._translate.use(this._translate.getDefaultLang());
      this._translate.use(lang);
      this._storage.store(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
    }
  }

  /**
   * Switches to the next language in the array
   * If the current language is the last one, it will switch to the first one
   */
  toggleLanguage(): void {
    this.setLanguage(languages[(languages.indexOf(this.getSelectedLanguage()) + 1) % languages.length]);
  }
}
