import { Component, ElementRef, ViewChild } from '@angular/core';
import { BreakpointService } from '@/common/services';
import { LayoutService } from '@/layout/services/layout.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent {
  @ViewChild('menuButton') menuButton!: ElementRef;
  @ViewChild('navbarMenu') menu!: ElementRef;

  constructor(
    protected breakpointService: BreakpointService,
    protected layoutService: LayoutService,
  ) {
  }

  menuButtons(): string {
    return this.breakpointService.lg() ? 'DESKTOP' : 'MOBILE';
  }
}
