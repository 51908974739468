import { effect, Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { BreakpointService } from '@/common/services';

export interface AppConfig {
  inputStyle: string;
  colorScheme: string;
  theme: string;
  ripple: boolean;
  scale: number;
}

interface LayoutState {
  staticMenuDesktopInactive: boolean;
  overlayMenuActive: boolean;
  profileSidebarVisible: boolean;
  configSidebarVisible: boolean;
  staticMenuMobileActive: boolean;
  menuHoverActive: boolean;
}

/* eslint-disable @typescript-eslint/member-ordering */
@Injectable({ providedIn: 'root' })
export class LayoutService {
  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
  };

  private _overlayOpen = new Subject<null>();
  overlayOpen$ = this._overlayOpen.asObservable();
  private _configUpdate = new Subject<AppConfig>();
  configUpdate$ = this._configUpdate.asObservable();

  private _config: AppConfig = {
    ripple: false,
    inputStyle: 'outlined',
    colorScheme: 'dark',
    theme: 'bedrock-tweaks-theme',
    scale: 14,
  };

  config = signal<AppConfig>(this._config);

  constructor(private _breakpointService: BreakpointService) {
    effect(() => {
      const config = this.config();
      if (this.updateStyle(config)) {
        this.changeTheme();
      }
      this.changeScale(config.scale);
      this.onConfigUpdate();
    });
  }

  updateStyle(config: AppConfig): boolean {
    return (
      config.theme !== this._config.theme ||
      config.colorScheme !== this._config.colorScheme
    );
  }

  showProfileSidebar(): void {
    this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
    if (this.state.profileSidebarVisible) {
      this._overlayOpen.next(null);
    }
  }

  showConfigSidebar(): void {
    this.state.configSidebarVisible = true;
  }

  isDesktop(): boolean {
    return this._breakpointService.lg();
  }

  isMobile(): boolean {
    return !this.isDesktop();
  }

  onConfigUpdate(): void {
    this._config = { ...this.config() };
    this._configUpdate.next(this.config());
  }

  changeTheme(): void {
    const config = this.config();
    const themeLink = document.getElementById('theme-css') as HTMLLinkElement;
    const themeLinkHref = themeLink.getAttribute('href')!;
    const newHref = themeLinkHref
      .split('/')
      .map(el => el === this._config.theme
        ? config.theme
        : el === `theme-${this._config.colorScheme}`
          ? `theme-${config.colorScheme}`
          : el)
      .join('/');

    this.replaceThemeLink(newHref);
  }

  replaceThemeLink(href: string): void {
    const id = 'theme-css';
    let themeLink = document.getElementById(id) as HTMLLinkElement;
    const cloneLinkElement = themeLink.cloneNode(true) as HTMLLinkElement;

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(
      cloneLinkElement,
      themeLink.nextSibling,
    );
    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
    });
  }

  changeScale(value: number): void {
    document.documentElement.style.fontSize = `${value}px`;
  }
}
