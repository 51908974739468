<div [ngClass]='containerClass' class='layout-wrapper'>
  <app-navbar />
  <p-toast />
  <div class='layout-main-container'>
    <div class='layout-main'>
      <router-outlet />
    </div>
  </div>
  <app-footer />
  <div class='layout-mask'></div>
</div>
