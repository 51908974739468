import { CanActivateFn } from '@angular/router';

export const githubGuard: CanActivateFn = (route, state) => {
  if (state.url === '/github') {
    document.defaultView?.open('https://github.com/DrAv0011');

    return false;
  }

  return true;
};
