import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpBackend, provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpLoaderFactory } from '@/modules/i18n';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    LayoutModule,
    NgxWebstorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    AngularQueryDevtools,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    provideHttpClient(),
    provideAngularQuery(new QueryClient()),
    provideLottieOptions({ player: () => player }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
