import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService as PrimeNGMessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { ParallaxDirective } from '@/common/directives/parallax.directive';
import { TranslationModule } from '@/modules/i18n';

@NgModule({
  declarations: [ParallaxDirective],
  imports: [
    CommonModule,
    FormsModule,
    SidebarModule,
    RadioButtonModule,
    ButtonModule,
    InputSwitchModule,
    TranslationModule,
    ToastModule,
    SkeletonModule,
  ],
  providers: [
    PrimeNGMessageService,
  ],
  exports: [ParallaxDirective],
})
export class AppCommonModule {
}
