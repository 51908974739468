import { Component } from '@angular/core';
import appVersion from '@/../../app_version.json';
import { LayoutService } from '@/layout/services/layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  version = 'v' + appVersion.version;
  year = new Date().getFullYear();

  constructor(public layoutService: LayoutService) {
  }
}

