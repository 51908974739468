import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { discordGuard, githubGuard } from '@/common/guards';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@/pages/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'errors',
        loadChildren: () => import('@/pages/errors/errors.module').then(m => m.ErrorsModule),
      },
    ],
  },
  {
    path: 'discord',
    canActivate: [discordGuard],
  },
  {
    path: 'github',
    canActivate: [githubGuard],
  },
  {
    path: '**',
    redirectTo: '/errors/not_found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
